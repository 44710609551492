import React from "react"

import { withGiftcardItem } from "./withGiftcardItem"
import { Buttons, Error } from "./GiftcardItemStyles"
import { StyledButton } from "../../Styled/Button"
import { Body } from "../../Styled/Text"

export const GiftcardItem = withGiftcardItem(
  ({ card, errors, show, handleShow }): JSX.Element => (
    <>
      {card ? (
        <>
          <Body spacing={"default"}>{card?.customer && <p>Issued to: {card?.customer?.name || card?.customer?.email}</p>}</Body>
          {card?.expires && <Body spacing={"default"}>Expires: {card?.expires}</Body>}
          <Body spacing={"default"}>
            Balance: {card?.balance?.remaining} / {card?.balance?.initial}
          </Body>
          <Body spacing={"default"}>
            <strong>Code: {show ? card?.code?.full : card?.code?.partial}</strong>
          </Body>
          <Buttons>
            <StyledButton onClick={handleShow} colour={"yellow"} wide>
              {show ? "Hide code" : "Show code"}
            </StyledButton>
            {/* {card?.enabled && (
              <StyledButton colour={"yellow"} wide disabled={applied || loading} onClick={handleApply}>
                {applied ? "Applied to cart" : "Apply to cart"}
              </StyledButton>
            )} */}
          </Buttons>
        </>
      ) : (
        <Error>No gift card to show</Error>
      )}

      {errors?.map(item => (
        <Error key={item?.toString()}>{item?.toString()}</Error>
      ))}
    </>
  )
)
