import React from "react"

import { withGiftcard } from "./withGiftcard"
import { Outer, Inner, Section, Title } from "./GiftCardStyles"
import { GiftcardItem } from "./Item/GiftcardItem"
import { RichText } from "../RichText/RichText"
import { ImageVideo } from "../ImageVideo/ImageVideo"

export const Giftcard = withGiftcard(
  ({ title, content, image, location }): JSX.Element => (
    <Outer>
      <Inner>
        <Section>
          <Title>{title}</Title>
          <RichText>{content}</RichText>
          <ImageVideo imageVideo={image} />
        </Section>
      </Inner>
      <Inner>
        <Section>
          <GiftcardItem location={location} />
        </Section>
      </Inner>
    </Outer>
  )
)
