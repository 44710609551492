import tw, { styled } from "twin.macro"
import { Body } from "../../Styled/Text"

export const Buttons = tw.div`
  grid grid-cols-2 gap-1 mb-2
`

export const Error = styled(Body)`
  ${tw`text-red`}
`
