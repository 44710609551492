import React from "react"

import { useGiftcard } from "../../../hooks/useGiftcard"

export const withGiftcardItem = Component => ({ name = "GiftcardItem", location }: any) => {
  const { applied, card, errors, loading, show, handleApply, handleShow } = useGiftcard(location)

  Component.displayName = name
  return <Component applied={applied} card={card} errors={errors} handleApply={handleApply} handleShow={handleShow} loading={loading} show={show} />
}
